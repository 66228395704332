import { createSlice } from '@reduxjs/toolkit'

// utils
import axios from '../../utils/axios'
import { dispatch }from '../store'

const initialState = {
  isLoading: false,
  error: null,
  reduceSalaires: [],
};

const slice = createSlice({
  name: 'reduceSalaires',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    
    getReductionSuccess(state, action) {
      state.isLoading = false;
      state.reduceSalaires = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getReduction() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/company/get-reduction-salary');
      dispatch(slice.actions.getReductionSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



export async function createReduceSalaire(data) {
    const formData = new FormData();
  
    // Ajoutez les données à formData
    formData.append('employee', data.employee);
    formData.append('reason', data.reason);
    formData.append('debitCredit', data.debitCredit);
  
    const response = await axios.post(`/api/company/reduce-salary`, formData, {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });
  
    return response.data;
  }
  

  export async function deleteReduction(reductionId) {
    const response = await axios.delete(`/api/company/delete-reduce-salary?reductionId=${reductionId}`);
    return response.data;
  }