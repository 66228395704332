// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/static/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  users: getIcon('ic_users'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  payroll: getIcon('ic_monney'),
  reduction: getIcon('ic_reduction'),
  credit:getIcon('ic_credit'),
  customer:getIcon('ic_customer'),
  caisse:getIcon('ic_caisse'),
  fournisor:getIcon('ic_fournisor'),
  preview:getIcon('ic_preview'),
  trace:getIcon('ic_trace')
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      // { title: 'home', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      // CATEGORIES
      // {
      //   title: 'Categories',
      //   path: PATH_DASHBOARD.category.root,
      //   icon: ICONS.ecommerce,
      //   children: [
      //     { title: 'Liste', path: PATH_DASHBOARD.category.list },
      //     { title: 'Ajouter', path: PATH_DASHBOARD.category.new },
      //   ],
      // },
      // PRODUCTS
      {
        title: 'Produits',
        path: PATH_DASHBOARD.product.root,
        icon: ICONS.cart,
        children: [
          { title: 'Liste', path: PATH_DASHBOARD.product.list },
          { title: 'Ajouter', path: PATH_DASHBOARD.product.new },
        ],
      },
      // RAPPORTS
      {
        title: 'Rapports',
        path: PATH_DASHBOARD.rapport.root,
        icon: ICONS.invoice,
        children: [
          { title: 'Liste', path: PATH_DASHBOARD.rapport.list },
          { title: 'tickets', path: PATH_DASHBOARD.rapport.ticket },
          { title: 'Inventaire', path: PATH_DASHBOARD.rapport.product },
          { title: 'Inventaire par Fournisseur', path: PATH_DASHBOARD.rapport.inventaire },
        ],
      },

      {
        title: 'Apercu',
        path: PATH_DASHBOARD.compteRendu.list,
        icon: ICONS.preview,
        children: [
          { title: 'Compte Rendu', path: PATH_DASHBOARD.compteRendu.list },
         
          
          
        ],
      },
      // etat caisse
      {
        title: 'Etat Caisse',
        path: PATH_DASHBOARD.etatCaisse.root,
        icon: ICONS.caisse,
        children: [
          { title: 'Caisse', path: PATH_DASHBOARD.etatCaisse.list },
          // { title: 'tickets', path: PATH_DASHBOARD.rapport.ticket },
          // { title: 'Inventaire', path: PATH_DASHBOARD.rapport.product },
        ],
      },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Gestion',
    items: [
      // STORE
      {
        title: 'Magasin',
        path: PATH_DASHBOARD.store.list,
        icon: ICONS.banking,
        children: [
          { title: 'Liste', path: PATH_DASHBOARD.store.list },
          { title: 'Ajouter', path: PATH_DASHBOARD.store.new },
        ],
      },

       // TRACABILITE
       {
        title: 'Tracabilité',
        path: PATH_DASHBOARD.tracabilite.company,
        icon: ICONS.trace,
        children: [
          { title: 'Tracabilité compagnie', path: PATH_DASHBOARD.tracabilite.company },
          { title: 'Tracabilité employé(e)', path: PATH_DASHBOARD.tracabilite.employee },
        ],
      },
      // USER
      {
        title: 'Employer',
        path: PATH_DASHBOARD.users.list,
        icon: ICONS.users,
        children: [
          { title: 'Liste', path: PATH_DASHBOARD.users.list },
          { title: 'Ajouter', path: PATH_DASHBOARD.users.new },
          {
            title: 'Reduction',
            path: PATH_DASHBOARD.reduceSalaire.list,
            icon: ICONS.reduction,
            children: [
              { title: 'Liste Ajustement', path: PATH_DASHBOARD.reduceSalaire.list },
              { title: 'Ajustement salaire', path: PATH_DASHBOARD.reduceSalaire.new },
            ],
          },

          {
            title: 'Superviseur',
            path: PATH_DASHBOARD.reduceSalaire.list,
            icon: ICONS.reduction,
            children: [
              { title: 'Liste', path: PATH_DASHBOARD.supervisors.list},
              // { title: 'Ajouter', path: PATH_DASHBOARD.supervisors.new},
            ],
          },
    
        ],
      },

      {
        title: 'Fournisseur',
        path: PATH_DASHBOARD.fournisseurs.root,
        icon: ICONS.fournisor,
        children: [
          { title: 'Liste', path: PATH_DASHBOARD.fournisseurs.list },
          { title: 'Ajouter', path: PATH_DASHBOARD.fournisseurs.new },

          {
            title: 'Registre',
            path: PATH_DASHBOARD.users.list,
            icon: ICONS.fournisor,
            children: [
           
              { title: 'registre-fournisseur', path: PATH_DASHBOARD.fournisseurs.registre },
              { title: 'Achat-fournisseur', path: PATH_DASHBOARD.fournisseurs.achat },
              { title: 'registre-paiement', path: PATH_DASHBOARD.fournisseurs.deposit },
            ],
          },
        ],
      },

     



      // CUSTOMER
      {
        title: 'Client',
        path: PATH_DASHBOARD.customers.list,
        icon: ICONS.customer,
        children: [
          { title: 'Liste', path: PATH_DASHBOARD.customers.list },
          { title: 'Ajouter', path: PATH_DASHBOARD.customers.new },
          { title: 'Tracabilité', path: PATH_DASHBOARD.customers.achat },
          {
            title: 'Credit',
            path: PATH_DASHBOARD.creditSales.list,
            icon: ICONS.credit,
            children: [
              { title: 'Liste', path: PATH_DASHBOARD.creditSales.list },
              
            ],
          },
        ],

        
      },


        // CreditSales
       
  
    


      {
        title: 'Payroll',
        path: PATH_DASHBOARD.payroll.list,
        icon: ICONS.payroll,
        children: [
          { title: 'Liste ', path: PATH_DASHBOARD.payroll.list },
          { title: 'Generer Payroll ', path: PATH_DASHBOARD.payroll.new },
        ],
      },
      
      // MY ACCOUNTS
      {
        title: 'Mon compte',
        path: PATH_DASHBOARD.users.account,
        icon: ICONS.user,
        children: [
          { title: 'Compte', path: PATH_DASHBOARD.users.account },
          { title: 'Documment', path: PATH_DASHBOARD.users.document},
        ],
      },
    ],
  },
];

export default navConfig;