import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  users: [],
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET USER
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getUsers() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/company/employees');
      dispatch(slice.actions.getUsersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export async function createUser(data) {
  const response = await axios.post(`/api/company/add-employee`, data);
  return response.data;
}

// ----------------------------------------------------------------------

export async function updateUser(data) {
  const response = await axios.put(`/api/company/update-employee`, data);
  return response.data;
}

// ----------------------------------------------------------------------

export async function bannedUser(id, banned) {
  const response = await axios.put(`/api/company/banned-employee`, {
    userId: id,
    is_block: banned,
  });
  return response.data;
}


export async function deleteUser(id) {
  const response = await axios.delete(`/api/company/delete-employee?userId=${id}`);
  return response.data;
}
