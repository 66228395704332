import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../store';

const initialState = {
  isLoading: false,
  error: null,
  measures: [], 
};

const slice = createSlice({
  name: 'measures',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MEASURES SUCCESS 
    getMeasuresSuccess(state, action) {
      state.isLoading = false;
      state.measures = action.payload; 
    },
  },
});

export default slice.reducer;

// ----------------------------------------------------------------------

export function getMeasures() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/product/measures');
      dispatch(slice.actions.getMeasuresSuccess(response.data)); 
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
