import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { Select, MenuItem, FormControl, InputLabel, Chip, FormHelperText, colors } from '@mui/material';

RHFMultiselect.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  getOptionLabel: PropTypes.func,
  border:PropTypes.string,
  textColor:PropTypes.string
};

export default function RHFMultiselect({ name, options, getOptionLabel,textColor, border,...other }) {
  const { control } = useFormContext();

  return (
    <FormControl fullWidth error={!!other.error}>
      <InputLabel>{other.label}</InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Select
            multiple
            {...field}
            label={other.label}
            value={field.value || []}
            onChange={(e) => field.onChange(e.target.value)}
            renderValue={(selected) => (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {selected?.map((value) => (
                  <Chip
                    key={value}
                  
                    style={{ 
                      backgroundColor: value ,
                      color:'black',
                      border:'1.5px solid black',
                      paddingLeft:'4px',
                      
                    }}
                  />
                ))}
              </div>
            )}
            {...other}
          >
            {options?.map((option) => (
              <MenuItem key={option[0]} value={option[0]}>
                {option[1]}
              </MenuItem>
            ))}
          </Select>
        )}
      />
  
    </FormControl>
  );
}