import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  fournisseurs: [],
  typeServices:[],
 
};

const slice = createSlice({
  name: 'fournisseur',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CUSTOMER
    getFournisseurSuccess(state, action) {
      state.isLoading = false;
      state.fournisseurs = action.payload;
    },

    
     // GET TYPE SERVICE
     getTypeServiceSuccess(state, action) {
        state.isLoading = false;
        state.typeServices = action.payload;
      },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getFournisseur() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/company/fournisseur');
      
      dispatch(slice.actions.getFournisseurSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//----------------------------------------------------------------------
export function getTypeService() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('/api/company/typeService');
        
        dispatch(slice.actions.getTypeServiceSuccess(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

// ----------------------------------------------------------------------

export async function createFournisseur(data) {
  const response = await axios.post(`/api/company/add-fournisseur`, data);
  return response.data;
}

// ----------------------------------------------------------------------

export async function updateFournisseur(data) {
  const response = await axios.put(`/api/company/update-fournisseur`, data);
  return response.data;
}

// ----------------------------------------------------------------------



export async function deleteFournisseur(id) {
  const response = await axios.delete(`/api/company/delete-fournisseur?fournisorId=${id}`);
  return response.data;
}

