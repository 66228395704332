import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { Select, MenuItem, FormControl, InputLabel, Chip, FormHelperText } from '@mui/material';

RHFMultiselectCategory.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  getOptionLabel: PropTypes.func,
  border: PropTypes.string,
  textColor: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
};

export default function RHFMultiselectCategory({ name, options, getOptionLabel, textColor, border, label, error, ...other }) {
  const { control } = useFormContext();

  return (
    <FormControl fullWidth error={!!error}>
      <InputLabel>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Select
            multiple
            {...field}
            label={label}
            value={field.value || []}
            onChange={(e) => field.onChange(e.target.value)}
            renderValue={(selected) => (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {selected.map((value) => {
                  const selectedOption = options.find(option => option.value === value);
                  return (
                    <Chip
                      key={value}
                      label={getOptionLabel ? getOptionLabel(value) : selectedOption?.label || value}
                      style={{
                        backgroundColor: selectedOption ? selectedOption.value : 'grey',
                        color: textColor || 'black',
                        paddingLeft: '4px',
                        margin: '2px',
                      }}
                    />
                  );
                })}
              </div>
            )}
            {...other}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  );
}
