import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';



const initialState = {
    isLoading: false,
    error: null,
    supervisors: [],
  };


  const slice = createSlice({
    name: 'supervisor',
    initialState,
    reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
  
      // GET USER
      getUsersSuccess(state, action) {
        state.isLoading = false;
        state.supervisors = action.payload;
      },
    },
  });

  // Reducer
export default slice.reducer;


export function getSupervisor() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('/api/company/get-supervisor');
        dispatch(slice.actions.getUsersSuccess(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }