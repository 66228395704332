import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  creditSales: [],
};

const slice = createSlice({
  name: 'creditSales',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CUSTOMER
    getCreditSalesSuccess(state, action) {
      state.isLoading = false;
      state.creditSales = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getCreditSales(storeId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/product/credit-list/${storeId}`, {
        // params: {
        //   store_id: storeId,
        
        // },
      });
      dispatch(slice.actions.getCreditSalesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

// export async function createCustomer(data) {
//   const response = await axios.post(`/api/company/add-customer`, data);
//   return response.data;
// }

// // ----------------------------------------------------------------------

// export async function updateCustomer(data) {
//   const response = await axios.put(`/api/company/update-customer`, data);
//   return response.data;
// }

// // ----------------------------------------------------------------------

export async function bannedCustomer(id, banned) {
  const response = await axios.put(`/api/company/banned-customer`, {
    customerId: id,
    is_block: banned,
  });
  return response.data;
}


export async function deleteCustomer(id) {
  const response = await axios.delete(`/api/company/delete-customer?customerId=${id}`);
  return response.data;
}
