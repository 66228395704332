


import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  cashregisters: [],
 
 
};

const slice = createSlice({
  name: 'cashregister',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CUSTOMER
    getCashRegisterSuccess(state, action) {
      state.isLoading = false;
      state.cashregisters = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getcashRegister() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/company/fournisseur');
      
      dispatch(slice.actions.getCashRegisterSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//----------------------------------------------------------------------


// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

export async function updateFournisseur(data) {
  const response = await axios.put(`/api/company/update-fournisseur`, data);
  return response.data;
}

// ----------------------------------------------------------------------



export async function deleteFournisseur(id) {
  const response = await axios.delete(`/api/company/delete-fournisseur?fournisorId=${id}`);
  return response.data;
}

