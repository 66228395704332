import { createSlice } from '@reduxjs/toolkit'

// utils
import axios from '../../utils/axios'
import { dispatch }from '../store'

const initialState={
    isLoading:false,
    error:null,
    sizes:[],
}

const slice=createSlice({
    name:'sizes',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
          state.isLoading = true;
        },
    
        // HAS ERROR
        hasError(state, action) {
          state.isLoading = false;
          state.error = action.payload;
        },
    
        // GET SIZE
        getSizeSuccess(state, action) {
          state.isLoading = false;
          state.sizes = action.payload;
        },
      },
})

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


export function getSizes() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/product/sizes');
      dispatch(slice.actions.getSizeSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
