import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const DateLa = (value) => {
  const year = value.getFullYear();
  const month = value.getMonth() + 1;
  const day = value.getDate();

  const dateString = `${year}-${month}-${day}`;
  return dateString;
};

const initialState = {
  isLoading: false,
  error: null,
  ProduitFournisseurs: [],
  ProduitFournisseursAll: [],
};

const slice = createSlice({
  name: 'ProduitFournisseur',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ProduitFournisseurs
    getProduitFournisseurssSuccess(state, action) {
      state.isLoading = false;
      state.ProduitFournisseurs = action.payload;
    },

    getProduitFournisseursAllsSuccess(state, action) {
      state.isLoading = false;
      state.ProduitFournisseursAll = action.payload;
    },

   
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------


export function getProduitFournisseursAll() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/company/produitFournisseur-all')
    
      // console.log('rsponse customer',response.data)
      dispatch(slice.actions.getProduitFournisseursAllsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getProduitFournisseurs(startDate, endDate,) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/company/produitFournisseur',{
        params: {
          start_date: DateLa(startDate),
          end_date: DateLa(endDate),
        
        },
      });
      // console.log('rsponse customer',response.data)
      dispatch(slice.actions.getProduitFournisseurssSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//----------------------------------------------------------------------


// ----------------------------------------------------------------------

export async function createProduitFournisseur(data) {
  const response = await axios.post(`/api/company/add-produitFournisseur`, data);
  return response.data;
}

// ----------------------------------------------------------------------

export async function updateProduitFournisseur(data) {
  const response = await axios.put(`/api/company/update-produitFournisseur`, data);
  return response.data;
}

// ----------------------------------------------------------------------




export async function deleteProduitFournisseur(id) {
  const response = await axios.delete(`/api/company/delete-produitFournisseur?ProduitFournisseurId=${id}`);
  return response.data;
}

