import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  stores: [],
};

const slice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET STORE
    getStoresSuccess(state, action) {
      state.isLoading = false;
      state.stores = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getStores() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/company/stores');
      dispatch(slice.actions.getStoresSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export async function createStore(data) {
  const response = await axios.post(`/api/company/add-store`, data);
  return response.data;
}

// ----------------------------------------------------------------------

export async function updateStore(data) {
  const response = await axios.put(`/api/company/update-store`, data);
  return response.data;
}

// ----------------------------------------------------------------------

export async function bannedStore(storeId, banned) {
  const response = await axios.put(`/api/company/banned-store`, {
    storeId,
    is_block: banned,
  });
  return response.data;
}

// ----------------------------------------------------------------------

export async function deleteStore(storeId) {
  const response = await axios.delete(`/api/company/delete-store?storeId=${storeId}`);
  return response.data;
}
