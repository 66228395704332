import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  categories: [],
  category: null,
};

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CATEGORIES
    getCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
    },

    // GET CATEGORY
    getCategorySuccess(state, action) {
      state.isLoading = false;
      state.category = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getCategories() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/company/categories');
      dispatch(slice.actions.getCategoriesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export async function createCategory(data) {
  const response = await axios.post(`/api/company/add-category`, data);
  return response.data;
}

// ----------------------------------------------------------------------

export async function updateCategory(data) {
  const response = await axios.put(`/api/company/update-category`, data);
  return response.data;
}

// ----------------------------------------------------------------------

export async function bannedCategory(categoryId, banned) {
  const response = await axios.put(`/api/company/banned-category`, {
    categoryId,
    is_active: banned,
  });
  return response.data;
}

export async function deleteCategory(categoryId) {
  const response = await axios.delete(`/api/company/delete-category?categoryId=${categoryId}`);
  return response.data;
}
