import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const DateLa = (value) => {
  const year = value.getFullYear();
  const month = value.getMonth() + 1;
  const day = value.getDate();

  const dateString = `${year}-${month}-${day}`;
  return dateString;
};

const initialState = {
  isLoading: false,
  error: null,
  paiementFournisseurs: [],
 
};

const slice = createSlice({
  name: 'PaiementFournisseur',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ProduitFournisseurs
    getPaiementFournisseursSuccess(state, action) {
      state.isLoading = false;
      state.paiementFournisseurs = action.payload;
    },


   
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------


export function getPaiementFournisseurs(startDate, endDate) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/company/paiementFournisseur', {
        params: {
          start_date: DateLa(startDate),
          end_date: DateLa(endDate),
          
        },
      });
      // console.log('rsponse customer',response.data)
      dispatch(slice.actions.getPaiementFournisseursSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



//----------------------------------------------------------------------


// ----------------------------------------------------------------------

export async function createPaiementFournisseur(data) {
  const response = await axios.post(`/api/company/add-PaiementFournisseur`, data);
  return response.data;
}

// ----------------------------------------------------------------------

// export async function updateProduitFournisseur(data) {
//   const response = await axios.put(`/api/company/update-produitFournisseur`, data);
//   return response.data;
// }

// ----------------------------------------------------------------------




// export async function deleteProduitFournisseur(id) {
//   const response = await axios.delete(`/api/company/delete-produitFournisseur?ProduitFournisseurId=${id}`);
//   return response.data;
// }

