import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  
  customerStores:[]
};

const slice = createSlice({
  name: 'customerStore',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

  

    // GET CUSTOMER
    getCustomerStoreSuccess(state, action) {
      state.isLoading = false;
      state.customerStores = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;






  export async function getCustomerStore(id) {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/company/customerStore/${id}`);
    //   dispatch(slice.actions.getCustomerStoreSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error; // Pour que l'erreur soit propagée à l'appelant, si nécessaire
    }
  }