import { createSlice } from '@reduxjs/toolkit'

// utils
import axios from '../../utils/axios'
import { dispatch }from '../store'

const initialState = {
  isLoading: false,
  error: null,
  employees: [],
  payrolls:[],
};

const slice = createSlice({
  name: 'payroll',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getMonthEmpSalaireSuccess(state, action) {
      state.isLoading = false;
      state.employees = action.payload // Mettez à jour le state avec les employés
    },

    getMonthPayrollSuccess(state, action) {
      state.isLoading = false;
      state.payrolls = action.payload // Mettez à jour le state avec les employés
    },
  },
});

// Reducer
export default slice.reducer;


export function getPayrolls() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/company/payrolls');
      dispatch(slice.actions.getMonthPayrollSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}




export async function createPayroll(data) {
  dispatch(slice.actions.startLoading());
  try {
    const formData = new FormData();
    formData.append('month', data.formattedMonth);
    formData.append('year', data.year);

    console.log('form data',formData)
    const response = await axios.post(
      `/api/company/add-payroll`,
      formData,
      {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
        },
        
      }
    );

    
    dispatch(slice.actions.getMonthEmpSalaireSuccess(response.data));

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}



export async function FindPayroll(id) {
  const response = await axios.get(`/api/company/find-payroll?id=${id}`);
  return response.data;
}

  





