import { createSlice } from '@reduxjs/toolkit'

// utils
import axios from '../../utils/axios'
import { dispatch }from '../store'

const initialState={
    isLoading:false,
    error:null,
    colors:[],
}


const slice=createSlice({
    name:'colors',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
          state.isLoading = true;
        },
    
        // HAS ERROR
        hasError(state, action) {
          state.isLoading = false;
          state.error = action.payload;
        },
    
        // GET COLOR
        getColorSuccess(state, action) {
          state.isLoading = false;
          state.colors = action.payload;
        },
      },
})


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getColors() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('/api/product/colors');
        dispatch(slice.actions.getColorSuccess(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  