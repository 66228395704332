import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { Select, MenuItem, FormControl, InputLabel, Chip, FormHelperText, colors ,TextField} from '@mui/material';

RHFMultiText.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  getOptionLabel: PropTypes.func,
  border:PropTypes.string,
  textColor:PropTypes.string
};
export default function RHFMultiText({ name, options, getOptionLabel, textColor, border, ...other }) {
    const { control } = useFormContext();
  
    return (
      <FormControl fullWidth error={!!other.error}>
        <InputLabel>{other.label}</InputLabel>
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Select
              multiple  // Active la sélection multiple
              {...field}
              label={other.label}
              value={field.value || []}
              onChange={(e) => field.onChange(e.target.value)}


              {...other}
            >
              {options?.map((option) => (
                <MenuItem key={option[0]} value={option[0]}>
                  {option[1]}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
    );
  }
  